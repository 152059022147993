import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import { useTransitionParameters } from "../hooks/useTransitionParameters"
import { linkResolver } from "../utils/linkResolver"
import { Elements } from "prismic-reactjs"

const PrismicLink = (
  type: Elements,
  element: any,
  content: string,
  children: any[],
  index: any,
): JSX.Element | null => {
  const { transitionLinkProps } = useTransitionParameters({ transitionStatus: `` })

  if (element.data.link_type === 'Document') {
    return (
      <TransitionLink
        to={linkResolver(element.data)}
        {...transitionLinkProps}
      >
        {content}
      </TransitionLink>
    )
  }

  if (element.data.link_type === 'Web') {
    const anchorProps = element?.data?.target === `_blank` ? {
      target: `_blank`,
      rel: `noopener noreferrer`,
    } : {}

    return (
      <a href={element.data.url} {...anchorProps}>
        {content}
      </a>
    )
  }

  return null
}

export default PrismicLink
export { PrismicLink }